import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { toast } from "react-toastify";
import { useNavigate } from "@tanstack/react-router";
import { useContext, useEffect } from "react";
import { AuthContext } from "../../helpers/authContext.tsx";
import { db, updateUserLoginData } from "../../helpers/firebase.tsx";
import { doc, getDoc, serverTimestamp, setDoc } from "firebase/firestore";
import * as Sentry from "@sentry/react";

export function GoogleSignIn() {
  const navigate = useNavigate({ from: "/login" });
  const { user } = useContext(AuthContext);

  useEffect(() => {
    if (user) {
      void navigate({ to: "/" });
    }
  }, [user, navigate]);

  const onGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    const auth = getAuth();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      const userDocRef = doc(db, "users", user.uid);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        await updateUserLoginData(user.uid);
      } else {
        await setDoc(userDocRef, {
          firstName: user.displayName?.split(" ")[0] || "",
          lastName: user.displayName?.split(" ").slice(1).join(" ") || "",
          email: user.email,
          createdAt: user.metadata.creationTime,
          lastLogin: serverTimestamp(),
          loginCount: 1,
        });
      }

      // wait a bit before navigating to ensure auth is updated
      await new Promise((resolve) => setTimeout(resolve, 500));
      toast(`Welcome ${user.displayName}`, {
        type: "success",
      });
    } catch (error) {
      Sentry.captureException(error);
      console.error(error);
      toast("Cannot sign you in for the moment. Please try again later.", {
        type: "error",
      });
    }
  };

  return (
    <button className="button is-dark mb-4" onClick={onGoogleSignIn}>
      <span className="icon">
        <i className="fab fa-google"></i>
      </span>
      <span>Sign in with Google</span>
    </button>
  );
}
