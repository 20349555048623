import { useState } from "react";
import { GoogleSignIn } from "./GoogleSignIn.tsx";
import { Register } from "./Register.tsx";
import { LoginForm } from "./LoginForm.tsx";
import { Welcome } from "../Welcome.tsx";

export function Login() {
  const [isLogin, setIsLogin] = useState(true);

  return (
    <>
      <div className="section ">
        <div className="columns is-desktop is-variable is-8">
          <div className="column is-hidden-mobile is-1-desktop "></div>
          <div className="column is-fixed-left is-hidden-mobile is-5-desktop pl-0 pr-0">
            <Welcome />
          </div>
          <div className="column is-hidden-mobile is-1-desktop "></div>
          <div className="column is-full-mobile is-4-desktop">
            <div className="box has-text-centered is-narrow">
              <div className="tabs is-centered is-toggle">
                <ul>
                  <li className={isLogin ? "is-active" : ""}>
                    <a onClick={() => setIsLogin(true)}>
                      <span>Login</span>
                    </a>
                  </li>
                  <li className={!isLogin ? "is-active" : ""}>
                    <a onClick={() => setIsLogin(false)}>
                      <span>Sign Up</span>
                    </a>
                  </li>
                </ul>
              </div>

              {/* Dynamic Form Content */}
              {isLogin ? <LoginForm /> : <Register />}

              <hr />
              <p>or</p>
              <GoogleSignIn />
              <p className="help has-text-grey-light is-medium">
                After signing in, you may be asked to enter your company
                information if not done previously.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Footer */}
      <footer className="footer paddingless has-background-white">
        <div className="content has-text-centered">
          <p>
            <a
              href="https://massiverocket.com/privacy-policy"
              className="is-underlined has-text-grey	"
              target="_blank"
            >
              Privacy
            </a>
          </p>
        </div>
      </footer>
    </>
  );
}
