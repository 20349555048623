export function Welcome() {
  return (
    <div className="has-text-centered">
      <div className="is-hidden-mobile p-5 m-2"></div>
      <h1 className="title is-size-2 has-text-weight-bold mb-6">
        Welcome to <br className="" />
        Braze Playground
      </h1>
      <div className="pr-6 pl-6">
        <p>
          Empower your marketing team to take full control. Braze Playground is
          your all-in-one platform to build, test, and perfect campaigns and
          canvases—end-to-end—without relying on engineering.
        </p>
        <br />
        <p>
          Streamline creativity, accelerate testing, and deliver impactful
          experiences with ease. Sign in now and start transforming your
          marketing workflows today!
        </p>
        <br />
        <p>
          <a
            href="https://insights.massiverocket.com/"
            className="is-underlined has-text-grey has-text-grey"
          >
            Massive Rocket Insights
          </a>
        </p>
      </div>
    </div>
  );
}
