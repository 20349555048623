import logo from "./assets/logo.png";
import Troubleshooting from "./Troubleshooting.tsx";
import { UserMenu } from "./UserMenu.tsx";
import { useContext, useState } from "react";
import { AuthContext } from "./helpers/authContext.tsx";
import { Link } from "@tanstack/react-router";

export default function NavBar() {
  const { userData } = useContext(AuthContext);
  const [isActive, setIsActive] = useState(false);

  const toggleMenu = () => {
    setIsActive(!isActive);
  };

  return (
    <nav
      className="navbar is-fixed-top is-danger"
      role="navigation"
      aria-label="main navigation"
    >
      <div className="container">
        <div className="navbar-brand">
          <a
            href="https://massiverocket.com/"
            className="navbar-item"
            target="_blank"
          >
            <img src={logo} alt="Massive Rocket Logo" className="navbar-logo" />
          </a>
          <div className="navbar-item">
            <span className="has-text-weight-bold has-text-white">
              Braze Playground
            </span>
            <span className="tag is-light">BETA</span>
          </div>
          <a
            role="button"
            className={`has-text-white navbar-burger ${isActive ? "is-active" : ""}`}
            aria-label="menu"
            aria-expanded={isActive ? "true" : "false"}
            onClick={toggleMenu}
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>

        <div className={`navbar-menu ${isActive ? "is-active" : ""}`}>
          <div className="navbar-start">
            <Link to="/" className="navbar-item has-text-white">
              Home
            </Link>
            {userData?.admin && (
              <Link to="/users" className="navbar-item has-text-white">
                Admin
              </Link>
            )}
            <a
              href="https://massiverocket.com/actionable-me"
              className="navbar-item has-text-white"
              target="_blank"
              title="Braze Acceleration Engine"
            >
              <span>actionable.me</span>
              <i className="fa-solid fa-arrow-up-right-from-square"></i>
            </a>
          </div>
          <div className="navbar-end">
            <Troubleshooting />
            <UserMenu />
          </div>
        </div>
      </div>
    </nav>
  );
}
