import { ChangeEvent } from "react";

interface Props {
  label: string;
  name: string;
  type?: string;
  help?: string | { __html: string };
  required?: boolean;
  maxLength?: number;
  min?: number;
  max?: number;
  list?: string;
  placeholder?: string;
  pattern?: string;
  iconLeft?: string;
  defaultValue?: string;
  disabled?: boolean;
  title?: string;
  multiple?: boolean;
  accept?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

export default function AppInput({ label, help, iconLeft, ...rest }: Props) {
  return (
    <div className="field">
      <label className="label" htmlFor={`${rest.name}-input`}>
        <span>{label}</span>
        {rest.required && <span className="has-text-danger"> *</span>}
      </label>
      <p className={`control ${iconLeft ? "has-icons-left" : ""}`}>
        {iconLeft && (
          <span className="icon is-small is-left">
            <i className={`fa ${iconLeft}`}></i>
          </span>
        )}
        <input id={`${rest.name}-input`} className="input" {...rest} />
      </p>
      {typeof help === "string" && help && <p className="help">{help}</p>}
      {typeof help !== "string" && help && (
        <p className="help" dangerouslySetInnerHTML={help}></p>
      )}
    </div>
  );
}
